import {
	Flex,
	Box,
	Input,
	InputGroup,
	InputRightElement,
	VStack,
	Text,
	Button,
	Grid,
	Avatar,
	useBreakpointValue,
	Heading,
	Container,
	HStack,
	Divider,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { SearchIcon } from "@chakra-ui/icons";
import { FiPlus } from "react-icons/fi";
import { useState, useEffect } from "react";
import {
	collection,
	getDocs,
	query,
	orderBy,
	Timestamp,
} from "firebase/firestore";
import { db, analytics } from "../services/firebase";
import { logEvent } from "firebase/analytics";
import { useToastContext } from "../context/toastContext";

const ForumPage: React.FC = () => {
	const [expandedQuestionIds, setExpandedQuestionIds] = useState<string[]>([]);
	const [questions, setQuestions] = useState<(Question & { id: string })[]>([]);
	const { addToast } = useToastContext();
	const [searchQuery, setSearchQuery] = useState("");
	const [searchResults, setSearchResults] = useState<
		(Question & { id: string })[]
	>([]);

	useEffect(() => {
		const fetchQuestions = async () => {
			try {
				const querySnapshot = await getDocs(
					query(collection(db, "questions"), orderBy("createdAt", "desc"))
				);
				const questionsData = querySnapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				})) as (Question & { id: string })[];
				setQuestions(questionsData);
				setSearchResults(questionsData);

				logEvent(analytics, "fetch_questions_success", {
					timestamp: new Date().toISOString(),
					number_of_questions: questionsData.length,
				});
			} catch (error) {
				addToast({
					title: "Error",
					description: "Failed to fetch questions.",
					status: "error",
					duration: 5000,
					isClosable: true,
				});

				logEvent(analytics, "fetch_questions_error", {
					description: (error as Error).message,
					timestamp: new Date().toISOString(),
				});
			}
		};

		fetchQuestions();
	}, [addToast]);

	const columns = useBreakpointValue({ sm: 1, xl: 2 });

	const toggleAnswers = (questionId: string) => {
		if (expandedQuestionIds.includes(questionId)) {
			setExpandedQuestionIds(
				expandedQuestionIds.filter((id) => id !== questionId)
			);
		} else {
			setExpandedQuestionIds([...expandedQuestionIds, questionId]);
		}
	};

	const filterQuestions = (
		questions: (Question & { id: string })[],
		searchQuery: string
	) => {
		const filteredResults = questions.filter(
			(question) =>
				question.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
				question.description
					.toLowerCase()
					.includes(searchQuery.toLowerCase()) ||
				question.tags.some((tag) =>
					tag.toLowerCase().includes(searchQuery.toLowerCase())
				)
		);
		setSearchResults(filteredResults);

		logEvent(analytics, "search_questions", {
			search_query: searchQuery,
			number_of_results: filteredResults.length,
			timestamp: new Date().toISOString(),
		});
	};

	const handleSearch = () => {
		filterQuestions(questions, searchQuery);
	};

	interface Question {
		title: string;
		description: string;
		profile_img: string;
		username: string;
		tags: string[];
		createdAt: Timestamp;
	}

	const truncateText = (text: string, maxLength = 100) => {
		if (text.length <= maxLength) {
			return text;
		}
		return text.slice(0, maxLength) + "...";
	};

	function methodDoesNotExist(): void {
		throw new Error("Function not implemented.");
	}

	return (
		<Box bg="#F3F4F6" minHeight="100vh">
			<Flex alignItems="center" direction="column" height="100vh-20px">
				<Heading
					marginTop={"30px"}
					size="xl"
					mb={4}
					fontWeight="800"
					color="#ed8936"
				>
					Forum
				</Heading>
				<Box marginTop="5px">
					{/* <button onClick={() => methodDoesNotExist()}>Break the world</button> */}

					<InputGroup width={{ base: "100%", md: "400px", lg: "600px" }}>
						<Input
							placeholder="Search..."
							width="100%"
							borderColor="gray.400"
							focusBorderColor="orange.400"
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
						/>
						<InputRightElement>
							<Button
								size="sm"
								backgroundColor="orange"
								variant="ghost"
								padding="0"
								onClick={handleSearch}
							>
								<SearchIcon />
							</Button>
						</InputRightElement>
					</InputGroup>
				</Box>
				<Box position="fixed"
					bottom="16"
					right="16"
					zIndex="10"
					boxShadow="md"
				>
					<HStack borderRadius="md">
						<Link to="/createquestion">
							<Button
								colorScheme={"orange"}
								variant="solid"
								leftIcon={<FiPlus />}
							>
								Create Question
							</Button>
						</Link>
					</HStack>
				</Box>
				<Grid
					templateColumns={`repeat(${columns}, 1fr)`}
					gap={6}
					width="80%"
					marginTop="20px"
					marginBottom="20px"
				>
					{searchResults.map((question) => (
						<Link key={question.id} to={`/thread/${question.id}`}>
							<Box
								p="20px"
								bg="white"
								borderWidth="1px"
								borderColor="#ccc"
								borderRadius="5px"
								_hover={{ bg: "gray.50" }}
								height="280px"
								overflow="hidden"
								position="relative"
							>
								<Flex alignItems="center" justifyContent="space-between">
									<Flex alignItems="center" mb={2}>
										<Avatar src={question.profile_img} size="sm" mr={2} />
										<Text fontWeight="bold">{question.username}</Text>
									</Flex>
									<Text fontSize="sm" color="gray.500" top={2} right={4}>
										{question.createdAt.toDate().toLocaleString()}
									</Text>
								</Flex>
								<VStack spacing={4} align="stretch" mt={4}>
									<Text size="lg" fontWeight="bold">
										{question.title}
									</Text>
									<hr style={{ margin: "0px" }}></hr>
									<Text>{truncateText(question.description)}</Text>
								</VStack>
								<Flex alignItems={"center"} mt={"5px"}>
									{question.tags.map((tag) => (
										<Text key={tag} color={"gray"} marginRight="5px">
											#{tag}
										</Text>
									))}
								</Flex>
							</Box>
						</Link>
					))}
				</Grid>
			</Flex>
		</Box>
	);
};

export default ForumPage;