import { 
  Box, 
  VStack, 
  HStack, 
  Button, 
  Input, 
  FormControl, 
  FormLabel, 
  Text, 
  Heading,
  Checkbox, 
  CheckboxGroup, 
  Stack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { Stepper } from './Stepper'; // Assuming Stepper is imported

interface PreferredCollegesProps {
  setView: (view: string) => void;
}

const CollegeAssessment: React.FC<PreferredCollegesProps> = ({ setView }) => {
  const [step, setStep] = useState(1);
  const [completed, setCompleted] = useState(false)

  const nextStep = () => setStep((s) => Math.min(s + 1, 3));
  const prevStep = () => setStep((s) => Math.max(s - 1, 1));

  const renderFormContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <FormControl id="gpa">
              <FormLabel fontSize="lg">Unweighted GPA</FormLabel>
              <Input size="sm" />
            </FormControl>
            <FormControl id="sat">
              <FormLabel fontSize="lg">SAT</FormLabel>
              <Input size="sm" />
            </FormControl>
            <FormControl id="nationality">
              <FormLabel fontSize="lg">Nationality</FormLabel>
              <Input size="sm" />
            </FormControl>
            <FormControl id="major">
              <FormLabel fontSize="lg">Major</FormLabel>
              <Input size="sm" />
            </FormControl>
          </>
        );
      case 2:
        return (
          <>
            <Text fontSize="xl" fontWeight="500">Colleges to Include</Text>
            <Text fontSize="md">Numbers of Reach Schools</Text>
            <Text fontSize="md">Numbers of Target Schools</Text>
            <Text fontSize="md">Numbers of Safety Schools</Text>
          </>
        );
      case 3:
        return (
          <Stack spacing={3}>
            <Text fontSize="lg" fontWeight="500">Include</Text>
            <CheckboxGroup colorScheme="blue">
              <Stack spacing={3} direction="column">
                <Checkbox value="background" size="lg">My background</Checkbox>
                <Checkbox value="essay" size="lg">My Essay</Checkbox>
                <Checkbox value="activities" size="lg">My activities</Checkbox>
              </Stack>
            </CheckboxGroup>
          </Stack>
        );
      default:
        return null;
    }
  };

  return (
    completed ? (
      <Box
        minH="90vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
        p={10}
        bg="gray.50"
      >
        <Box
          maxW="800px"
          w="100%"
          bg="white"
          p={10}
          boxShadow="xl"
          borderRadius="lg"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          textAlign="center"
          alignItems="center"
        >
          <Text fontSize="xl" fontWeight="500">Congrats!</Text>
          <Text fontSize="lg" mt={4} ml={10} mr={10}>You can now see your college recommendation and your college match scores on the college match list.</Text>
          <Button size="lg" colorScheme="blue" mt={20} onClick={() => setView('default')}>Close</Button>
        </Box>
      </Box>
    ) : (
      <Box
        minH="90vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
        p={10}
        bg="gray.50"
      >
        <HStack
          align="flex-start"
          spacing={16}
          maxW="800px"
          w="100%"
          bg="white"
          p={10}
          boxShadow="xl"
          borderRadius="lg"
        >
          <Stepper currentStep={step} />
          <VStack align="stretch" spacing={6} w="100%" ml="100px">
            {renderFormContent()}
            <HStack justify="space-between">
              <Button size="lg" onClick={prevStep} isDisabled={step === 1}>
                Back
              </Button>
              {step === 3 ? (
                <Button size="lg" colorScheme="blue" onClick={() => setCompleted(!completed)}>
                  Submit
                </Button>
              ) : (
                <Button size="lg" colorScheme="blue" onClick={nextStep}>
                  Next
                </Button>
              )}
            </HStack>
          </VStack>
        </HStack>
      </Box>
    )
  );
};

export default CollegeAssessment;