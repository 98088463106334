import { Box, VStack, HStack, Circle, Icon, Text } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

interface StepProps {
  step: number;
  currentStep: number;
  label: string;
  sublabel: string;
  completed: boolean;
}

const Step = ({ step, currentStep, label, sublabel, completed }: StepProps) => (
  <HStack alignItems="top">
    <VStack mr={2}>
      <Circle
        size="40px"
        bg={completed ? 'blue.500' : 'transparent'}
        border="2px solid"
        borderColor={completed ? 'blue.500' : 'gray.300'}
        color="white"
      >
        {completed ? <CheckIcon /> : <Text>{step}</Text>}
      </Circle>
      {step !== 3 && (
        <Box h="40px" w="2px" bg={completed ? 'blue.500' : 'gray.300'} />
      )}
    </VStack>
    <Box display="flex" flexDirection="column">
      <Text fontSize="lg" fontWeight={currentStep === step ? 'bold' : 'normal'}>{label}</Text>
      <Text fontSize="sm" color="gray">{sublabel}</Text>
    </Box>
  </HStack>
);

export const Stepper = ({ currentStep }: { currentStep: number }) => (
  <VStack align="stretch" spacing={4}>
    <Step step={1} currentStep={currentStep} label="Step 1" sublabel="Bio" completed={currentStep > 1} />
    <Step step={2} currentStep={currentStep} label="Step 2" sublabel="Options" completed={currentStep > 2} />
    <Step step={3} currentStep={currentStep} label="Step 3" sublabel="Additional" completed={false} />
  </VStack>
);