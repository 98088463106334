import { useState } from 'react';
import { Box, Grid, VStack, Button, Text } from '@chakra-ui/react';
import PreferredColleges from '../components/PreferredColleges';
import CollegeAssessment from '../components/CollegeAssessment';
import ReachColleges from '../components/ReachColleges';
import TargetColleges from '../components/TargetColleges';
import SafetyColleges from '../components/SafetyColleges';

const CollegeMatch = () => {
  const [view, setView] = useState('default');

  const handlePreferredClick = (label: string) => {
    setView(label);
  };

  return (
    view === "assessment" ? (
      <CollegeAssessment setView={setView} />
    ) : (
      <Grid
        templateColumns="repeat(2, 1fr)"
        gap={6}
        height="90vh"
        p={6}
      >
        {/* Left Section: College Match List */}
        <Box
          border="1px"
          borderColor="gray.200"
          borderRadius="md"
          p={6}
          textAlign="center"
        >
          {view === 'Preferred' ? (
            <PreferredColleges setView={setView} />
          ) : view === "Reach" ? (
            <ReachColleges setView={setView} />
          ) : view === "Target" ? (
            <TargetColleges setView={setView} />
          ) : view === "Safety" ? (
            <SafetyColleges setView={setView} />
          ) : (
            <>
              <Text fontSize="2xl" fontWeight="500" mb={6}>
                College match Lists
              </Text>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={4}
                height="80%"
              >
                {['Preferred', 'Reach', 'Target', 'Safety'].map((label) => (
                  <Box
                    key={label}
                    bg="blue.500"
                    color="white"
                    borderRadius="md"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    cursor="pointer"
                    onClick={() => handlePreferredClick(label)}
                  >
                    <Text fontSize="xl" fontWeight="500">{label}</Text>
                  </Box>
                ))}
              </Grid>
            </>
          )}
        </Box>
  
        {/* Right Section: Assessment */}
        <Box
          bg="white"
          borderRadius="md"
          boxShadow="lg"
          textAlign="center"
          p={6}
          backgroundImage="url('https://source.unsplash.com/random')"
          backgroundSize="cover"
          backgroundPosition="center"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <VStack
            bg="white"
            borderRadius="lg"
            p={8}
            spacing={8}
            boxShadow="xl"
          >
            <Text fontSize="xl" fontWeight="500">
              Uncertain about your university choice?
            </Text>
            <Text>Try this AI-based assessment to help you decide!</Text>
            <Button colorScheme="blue" onClick={() => handlePreferredClick("assessment")}>Take Assessment</Button>
          </VStack>
        </Box>
      </Grid>
    )
  );
};

export default CollegeMatch;