import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ForumPage from './pages/ForumPage';
import { ToastProvider } from "./context/toastContext";
import { BlogPage } from './pages/BlogPage';
import CollegeMatch from './pages/CollegeMatch';

function App() {
  return (
    <BrowserRouter>
      <ToastProvider>
        <Routes>
          <Route path="/" element={<ForumPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/college-match" element={<CollegeMatch />} />
        </Routes>
        </ToastProvider>
    </BrowserRouter>
  );
}

export default App;
