import {
    Box,
    Flex,
    HStack,
    Link,
    IconButton,
    Button,
    useDisclosure,
    Stack,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
  } from '@chakra-ui/react';
  import { HamburgerIcon, CloseIcon, ChevronDownIcon } from '@chakra-ui/icons';
  
  interface NavLinkProps {
    children: React.ReactNode;
    to: string;
  }
  
  const NavLink = ({ children, to }: NavLinkProps) => (
    <Link
      px={2}
      py={1}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bg: 'gray.200',
      }}
      href={to}
    >
      {children}
    </Link>
  );
  
  export default function Navbar() {
    const { isOpen, onOpen, onClose } = useDisclosure();
  
    return (
      <>
        <Box px={4}>
          <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
            <IconButton
              size={'md'}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={'Open Menu'}
              display={{ md: 'none' }}
              onClick={isOpen ? onClose : onOpen}
            />
            <HStack spacing={8} alignItems={'center'}>
              <HStack
                as={'nav'}
                spacing={4}
                display={{ base: 'none', md: 'flex' }}
              >
                <NavLink to={'/'}>Logo</NavLink>
                <NavLink to={'/about'}>AI Counselor</NavLink>
                <Menu>
                  <MenuButton
                    as={Link}
                    px={2}
                    py={1}
                    rounded={'md'}
                    _hover={{
                      textDecoration: 'none',
                    }}
                  >
                    Documents <ChevronDownIcon />
                  </MenuButton>
                  <MenuList>
                    <Flex>
                      <Box p={4}>
                        <MenuItem as={Link} href={'/documents/essay'} _focus={{ boxShadow: 'none'}}>
                          My Essays
                        </MenuItem>
                        <MenuItem as={Link} href={'/documents/common-app'} _focus={{ boxShadow: 'none'}}>
                          My Resume
                        </MenuItem>
                      </Box>
                      <MenuDivider />
                      <Box p={4}>
                        <MenuItem as={Link} href={'/documents/resume'} _focus={{ boxShadow: 'none'}}>
                          Letters
                        </MenuItem>
                        <MenuItem as={Link} href={'/documents/letters'} _focus={{ boxShadow: 'none'}}>
                          Other
                        </MenuItem>
                      </Box>
                    </Flex>
                  </MenuList>
                </Menu>
                <NavLink to={'/community'}>Community</NavLink>
                <NavLink to={'/blog'}>Blog</NavLink>
                <NavLink to={'/college-match'}>College Match</NavLink>
              </HStack>
            </HStack>
            <Flex alignItems={'center'}>
              <Button
                variant={'solid'}
                colorScheme={'orange'}
                size={'sm'}
                mr={4}
              >
                Sign Up
              </Button>
              <Button variant={'outline'} colorScheme={'orange'} size={'sm'}>
                Log In
              </Button>
            </Flex>
          </Flex>
  
          {isOpen ? (
            <Box pb={4} display={{ md: 'none' }}>
              <Stack as={'nav'} spacing={4}>
                <NavLink to={'/'}>Home</NavLink>
                <NavLink to={'/about'}>About</NavLink>
                <NavLink to={'/documents'}>Documents</NavLink>
                <NavLink to={'/services'}>Services</NavLink>
                <NavLink to={'/contact'}>Contact</NavLink>
              </Stack>
            </Box>
          ) : null}
        </Box>
      </>
    );
  }