import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from '@chakra-ui/pro-theme'
import { extendTheme } from '@chakra-ui/react'
import Navbar from './components/Navbar';

const proTheme = extendTheme(theme)
const extenstion = {
  colors: { ...proTheme.colors, brand: proTheme.colors.orange },
}
const myTheme = extendTheme(extenstion, proTheme)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={myTheme}>
      <Navbar/>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);

reportWebVitals();
