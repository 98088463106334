import { useState } from 'react';
import { Box, IconButton, Table, Thead, Tbody, Tr, Th, Td, Input, InputGroup, InputLeftElement, Link } from '@chakra-ui/react';
import { ArrowBackIcon, SearchIcon } from '@chakra-ui/icons';
import CollegeDetail from './CollegeDetail';

interface TargetCollegesProps {
  setView: (view: string) => void;
}

const TargetColleges: React.FC<TargetCollegesProps> = ({ setView }) => {
  const [selectedCollege, setSelectedCollege] = useState<string | null>(null);
  const [score, setScore] = useState<number | null>(null);

  // Handle click on view details
  const handleViewDetails = (college: string, score: number) => {
    setSelectedCollege(college);
    setScore(score);
  };

  // Handle close detail view
  const handleCloseDetailView = () => {
    setSelectedCollege(null);
    setScore(null);
  };
  
  return (
    <Box
      border="1px"
      borderColor="gray.200"
      borderRadius="md"
      p={6}
      textAlign="left"
      height="100%"
    >
      {selectedCollege ? (
        <CollegeDetail 
          collegeName={selectedCollege} 
          score={score || 0} 
          onClose={handleCloseDetailView} 
        />
      ) : (
        <>
          {/* Back Button */}
          <IconButton
            icon={<ArrowBackIcon />}
            aria-label="Back"
            variant="ghost"
            mb={4}
            onClick={() => setView('default')}
          />

          {/* Title */}
          <Box fontSize="xl" mb={4} fontWeight="bold">
            Target
          </Box>

          {/* Search Box */}
          <InputGroup mb={4}>
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.400" />
            </InputLeftElement>
            <Input type="text" placeholder="Search for colleges..." />
          </InputGroup>

          {/* Table */}
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Institution</Th>
                <Th>Score</Th>
                <Th>Details</Th>
              </Tr>
            </Thead>
            <Tbody>
              {['Stanford', 'Stanford', 'Stanford', 'Stanford'].map((institution, index) => (
                <Tr key={index}>
                  <Td>{institution}</Td>
                  <Td>270</Td>
                  <Td>
                    <Link color="blue.500" onClick={() => handleViewDetails('Stanford', 270)}>view details</Link>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      )}
    </Box>
  );
};

export default TargetColleges;