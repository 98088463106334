import React from 'react';
import { Box, Button, Heading, Image, Text } from '@chakra-ui/react';
import standfordCampus from "../stanford-campus.jpg"

interface CollegeDetailProps {
  collegeName: string;
  score: number;
  onClose: () => void;
}

const CollegeDetail: React.FC<CollegeDetailProps> = ({ collegeName, score, onClose }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      borderRadius="lg"
      p={6}
      boxShadow="xl"
      bg="white"
      width="100%"
      mx="auto"
      my="6"
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Heading fontSize="3xl">
          {collegeName}
        </Heading>
        <Button onClick={onClose} colorScheme="blue" ml={4}>
          Close
        </Button>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {/* Image of the College */}
        <Image
          src={standfordCampus}
          alt={collegeName}
          borderRadius="md"
          boxSize="300px"
          objectFit="cover"
          mr={6}
        />
        
        {/* Score Explanation */}
        <Box
          border="1px"
          borderColor="blue.400"
          borderRadius="lg"
          p={6}
          flex="1"
          height="300px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Text fontWeight="bold" mb={2} fontSize="xl">{collegeName}</Text>
          <Text mb={2}>Total score: {score}</Text>
          <Text mb={2}>Score Explain: Bit too low to become one of the member</Text>
          <Text fontWeight="bold">Ways to improve:</Text>
          <Text>1. Increase GPA</Text>
          <Text>2. Increase SAT</Text>
        </Box>
      </Box>
    </Box>
  );
};

export default CollegeDetail;