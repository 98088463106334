import React, { createContext, useContext, ReactNode } from "react";
import { useToast, ToastId, UseToastOptions } from "@chakra-ui/react";

interface ToastContextProps {
	addToast: (options: UseToastOptions) => ToastId;
}

const ToastContext = createContext<ToastContextProps | undefined>(undefined);

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const toast = useToast();

	const addToast = (options: UseToastOptions) => {
		return toast({
			...options,
			position: "top-right", // Force all toasts to top-right
		});
	};

	return (
		<ToastContext.Provider value={{ addToast }}>
			{children}
		</ToastContext.Provider>
	);
};

export const useToastContext = () => {
	const context = useContext(ToastContext);
	if (!context) {
		throw new Error("useToastContext must be used within a ToastProvider");
	}
	return context;
};